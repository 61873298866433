/* eslint-disable no-unused-expressions */
// This is `@middleware/router-auth.js`

export default function ({ store, redirect, route }) {
  store.state.user.user != null &&
  (route.name === 'login' || route.name === 'login-register')
    ? redirect('/app')
    : ''
  store.state.user.user == null && isAppRoute(route) ? redirect('/login') : ''
}

function isAppRoute (route) {
  if (route.matched.some(record => record.path === '/app')) {
    return true
  }
}
