import globalActions from '../globals/StoreActions.js'
import globalMutations from '../globals/StoreMutations.js'
import globalGetters from '../globals/StoreGetters.js'
export const state = () => ({
  collection: 'locations',
  unsubscribe: null,
  data: [],
  localLinks: [
    {
      city: 'Spokane',
      state: 'WA',
      linkTemplate:
        'https://cp.spokanecounty.org/SCOUT/propertyinformation/Default.aspx?PID=%%streetAddress%% %%streetName%%'
    },
    {
      city: 'Spokane Valley',
      state: 'WA',
      linkTemplate:
        'https://cp.spokanecounty.org/SCOUT/propertyinformation/Default.aspx?PID=%%streetAddress%% %%streetName%%'
    }
  ]
})
export const mutations = {
  ...globalMutations
}
export const actions = {
  ...globalActions
}
export const getters = {
  ...globalGetters
}
