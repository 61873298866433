import globalActions from '../globals/StoreActions.js'
import globalMutations from '../globals/StoreMutations.js'
import globalGetters from '../globals/StoreGetters.js'
export const state = () => ({
  user: null,
  data: [],
  dto: {
    docId: '',
    email: '',
    password: ''
  },
  schema: {
    email: {
      cols: 12,
      type: 'username',
      label: 'Email',
      rules: {
        required: true
      }
    },
    password: {
      cols: 12,
      type: 'password',
      label: 'Password',
      rules: {
        required: true
      }
    },
    submit: {
      type: 'submit',
      label: 'Login'
    }
  }
})
export const actions = {
  ...globalActions
}
export const mutations = {
  ...globalMutations
}
export const getters = {
  ...globalGetters
}
