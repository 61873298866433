var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"dark":""}},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogClearAll),callback:function ($$v) {_vm.dialogClearAll=$$v},expression:"dialogClearAll"}},[_c('v-card',{attrs:{"elivation":"4","outlined":"","dark":""}},[_c('v-card-title',{staticClass:"justify-center"},[_c('p',[_vm._v("\n          This will "),_c('strong',[_c('i',[_vm._v("permanently")])]),_vm._v(" delete all of your\n          current addresses!\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          Normally you would only be doing this when starting a new territory.\n        ")]),_vm._v(" "),_c('p',[_vm._v("Are you ABSOLUTELY sure you want to do this?")])]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.dialogClearAll = false}}},[_vm._v("\n          Cancel\n        ")]),_vm._v(" "),_c('v-btn',{attrs:{"outlined":"","color":"grey"},on:{"click":_vm.clearAll}},[_vm._v("\n          OK\n        ")]),_vm._v(" "),_c('v-spacer')],1)],1)],1),_vm._v(" "),_c('v-app-bar',{attrs:{"app":"","fixed":"","color":"grey darken-3","dense":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Welcome, "+_vm._s(_vm.user_meta.first_name))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.$nuxt.$route.name === 'app')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"nuxt":"","to":"/app/spreadsheet"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-3"},[_vm._v("\n            mdi-grid\n          ")]),_vm._v("\n          Switch to Spreadsheet Mode\n        ")],1)]}}],null,false,2491326724)},[_vm._v(" "),_c('span',[_vm._v("Switch to Spreadsheet Mode")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"nuxt":"","to":"/app/"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-3"},[_vm._v("\n            mdi-map\n          ")]),_vm._v("\n          Switch to Map Mode\n        ")],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Switch to Map Mode")])]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.locations.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('download-excel',{staticClass:"v-btn v-btn--icon v-btn--round theme--dark v-size--default",staticStyle:{"cursor":"pointer"},attrs:{"fields":_vm.json_fields,"data":_vm.exportLocations,"type":"csv","name":_vm.filename}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}],null,false,2168408385)},[_vm._v(" "),_c('span',[_vm._v("Download Spreadsheet")])]):_vm._e(),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.dialogClearAll = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-sweep")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Clear all Addresses")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.logout($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Logout")])])],1),_vm._v(" "),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('nuxt',{attrs:{"keep-alive":""}})],1)],1),_vm._v(" "),_c('v-footer',{attrs:{"fixed":"","app":""}},[_c('span',[_vm._v("© "+_vm._s(new Date().getFullYear()))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }