export default {
  startDataSync ({ commit, state, rootState }) {
    const db = this.$fireModule.firestore()
    db.collection(
      'users/' + rootState.user.user.uid + '/' + state.collection
    ).onSnapshot((querySnapshot) => {
      const sends = []
      querySnapshot.forEach(
        (doc) => {
          const send = doc.data()
          send.docId = doc.id
          sends.push(send)
        },
        (error) => {
          error = ''
          console.log('disconnected')
        }
      )
      commit('set_data', sends)
    })
  },
  itemStore ({ commit, state, rootState }, item) {
    if (rootState.user.user) {
      item.uid = rootState.user.user.uid
      const db = this.$fireModule.firestore()
      if (Object.prototype.hasOwnProperty.call(item, 'docId')) {
        const docId = item.docId
        delete item.docId
        db.collection('users')
          .doc(rootState.user.user.uid)
          .collection(state.collection)
          .doc(docId)
          .set(item)
      } else {
        db.collection(
          `users/${rootState.user.user.uid}/${state.collection}`
        ).add(item)
      }
    }
  },
  itemDelete ({ commit, state, rootState }, docId) {
    const db = this.$fireModule.firestore()
    db.collection('users')
      .doc(rootState.user.user.uid)
      .collection(state.collection)
      .doc(docId)
      .delete()
  },
  collectionDelete ({ commit, state, rootState }) {
    const db = this.$fireModule.firestore()
    state.data.forEach((item) => {
      db.collection('users')
        .doc(rootState.user.user.uid)
        .collection(state.collection)
        .doc(item.docId)
        .delete()
    })
  }
}
