import Vue from 'vue'

if (!Vue.__my_mixin__) {
  Vue.__my__mixin__ = true
  Vue.mixin({
    methods: {
      isEmptyObject (obj) {
        return JSON.stringify(obj) === '{}'
      },
      cloneObj (obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      objHas (obj, field) {
        return Object.prototype.hasOwnProperty.call(obj, field)
      },
      objHasAndNotEmpty (obj, field) {
        if (this.objHas(obj, field)) {
          if (obj[field]) {
            return true
          }
        }
        return false
      },
      localAddress (item) {
        const linkInfo = this.$store.state.locations.localLinks.find(
          (localLink) => {
            return (
              localLink.city === item.city && localLink.state === item.state
            )
          }
        )
        if (linkInfo) {
          let localLinkTemplate = linkInfo.linkTemplate
          for (const [key, value] of Object.entries(item)) {
            if (value) {
              if (typeof value === 'string') {
                localLinkTemplate = localLinkTemplate.replace(
                  `%%${key}%%`,
                  value.toUpperCase()
                )
              }
            }
          }
          return encodeURI(localLinkTemplate)
        }
        return false
      }
    }
  })
}
