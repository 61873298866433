//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/'
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Inspire',
          to: '/inspire'
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js'
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  watch: {
    user () {
      if (this.user) {
        this.$store.dispatch('user/startUserMetaSync')
      }
      if (!this.user) {
        this.$router.push({
          path: '/login'
        })
      } else if (
        this.$route.name === 'login' ||
        this.$route.name === 'login-register'
      ) {
        this.$router.push({
          path: '/app'
        })
      }
    }
  }
}
