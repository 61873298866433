//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      overlay: false,
      dialogClearAll: false,
      json_fields: {
        Name: 'name',
        Phone: 'phone',
        'House Number': 'streetAddress',
        Street: 'streetName',
        Address: 'fullAddress',
        City: 'city',
        State: 'state',
        Zip: 'zip',
        Notes: 'notes',
        Completed: 'completed'
      }
    }
  },
  computed: {
    filename () {
      return this.$moment().format('YYYY-MM-DD') + '-addresses.csv'
    },
    locations () {
      return this.$store.state.locations.data
    },
    exportLocations () {
      let locations = this.cloneObj(this.locations)
      locations = locations.map((location) => {
        location.fullAddress = `${location.streetAddress} ${location.streetName}`
        return location
      }).sort((a, b) => {
        // compare street names first
        const nameCompare = a.streetName.localeCompare(b.streetName)
        if (nameCompare !== 0) {
          return nameCompare
        }
        // if street names are equal, compare street addresses
        // extracting numbers from the address string
        const numA = parseInt(a.streetAddress.match(/\d+/)[0])
        const numB = parseInt(b.streetAddress.match(/\d+/)[0])

        if (!isNaN(numA) && !isNaN(numB)) {
          // both addresses start with numbers, compare them
          return numA - numB
        } else if (!isNaN(numA)) {
          // only a starts with a number, so it comes first
          return -1
        } else if (!isNaN(numB)) {
          // only b starts with a number, so it comes first
          return 1
        } else {
          // neither starts with a number, compare as strings
          return a.streetAddress.localeCompare(b.streetAddress)
        }
      })
      return locations
    },

    user () {
      return this.$store.state.user.user
    },
    user_meta () {
      return this.$store.state.user.user_meta
    }
  },
  watch: {
    locations () {
      if (this.locations.length === 0) {
        this.overlay = false
      }
    },
    user () {
      if (this.user) {
        this.$store.dispatch('user/startUserMetaSync')
      }
      if (!this.user) {
        this.$router.push({
          path: '/login'
        })
      } else if (
        this.$route.name === 'login' ||
        this.$route.name === 'login-register'
      ) {
        this.$router.push({
          path: '/app'
        })
      }
    }
  },
  mounted () {
    if (this.user) {
      this.$store.dispatch('user/startUserMetaSync')
    }
  },
  methods: {
    clearAll () {
      this.overlay = true
      this.$store.dispatch('locations/collectionDelete')
      this.dialogClearAll = false
    },
    async logout () {
      try {
        await this.$fire.auth.signOut()
      } catch (e) {
        alert(e)
      }
    }
  }
}
