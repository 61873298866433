import globalActions from '../globals/StoreActions.js'
import globalMutations from '../globals/StoreMutations.js'
import globalGetters from '../globals/StoreGetters.js'
export const state = () => ({
  user: null,
  user_meta: {},
  data: [],
  dto: {
    first_name: '',
    last_name: '',
    city: '',
    state: '',
    email: '',
    password: ''
  },
  schema: {
    first_name: {
      cols: 6,
      autocomplete: 'off',
      type: 'text',
      label: 'First Name',
      rules: {
        required: true
      }
    },
    last_name: {
      cols: 6,
      autocomplete: 'off',
      type: 'text',
      label: 'Last Name',
      rules: {
        required: true
      }
    },
    city: {
      cols: 6,
      autocomplete: 'off',
      type: 'text',
      label: 'City',
      rules: {
        required: true
      }
    },
    state: {
      cols: 6,
      autocomplete: 'off',
      type: 'state',
      label: 'State',
      rules: {
        required: true
      }
    },
    email: {
      cols: 12,
      autocomplete: 'off',
      type: 'username',
      label: 'Email',
      rules: {
        required: true
      }
    },
    password: {
      cols: 12,
      autocomplete: 'off',
      type: 'password',
      label: 'Password',
      rules: {
        required: true
      }
    },
    submit: {
      type: 'submit',
      label: 'Register'
    }
  }
})
export const actions = {
  ...globalActions,
  onAuthStateChangedAction: (
    { commit, state, rootState },
    { authUser, claims }
  ) => {
    if (!authUser) {
      commit('set_user_meta', {})
      commit('locations/set_data', [], { root: true })
      // this.rootState.locations.data = []
    } else {
      // Do something with the authUser and the claims object...
    }
  },
  startUserMetaSync ({ commit, state, rootState }) {
    const db = this.$fireModule.firestore()
    db.collection('users')
      .doc(rootState.user.user.uid)
      .onSnapshot(
        (doc) => {
          commit('set_user_meta', doc.data())
        },
        (error) => {
          error = ''
          console.log('disconnected')
        }
      )
  },
  userMetaStore ({ commit, state, rootState }, item) {
    const db = this.$fireModule.firestore()
    db.collection('users')
      .doc(state.user.uid)
      .set(item)
      .then(() => {})
  }
}
export const mutations = {
  ...globalMutations,
  set_user_meta: (state, data) => {
    state.user_meta = data
  },
  set_user: (state, data) => {
    state.user = data
  },
  ON_AUTH_STATE_CHANGED_MUTATION: (state, { authUser, claims }) => {
    if (!authUser) {
      state.user = null
      // console.log('logged out')
    } else {
      const { uid, email, emailVerified } = authUser
      state.user = { uid, email, emailVerified }
    }
  }
}
export const getters = {
  ...globalGetters
}
